import type { FetchContext, ResponseType, FetchResponse } from 'ofetch'

type OnResponseInterceptor = (
  ctx: FetchContext<unknown, ResponseType> & {
    response: FetchResponse<ResponseType>
  },
) => void

type OnRequestInterceptor = (ctx: FetchContext<unknown, ResponseType>) => void

type UseFetchInterceptor = {
  onResponse?: OnResponseInterceptor
  onRequest?: OnRequestInterceptor
}

/**
 * Returns a callback that can be used for the onResponse fetch interceptor.
 *
 * The callback handles propagating cacheability metadata from our API routes.
 * For example, when doing a search query during SSR and that search query
 * response has a max age of 5 minutes, we also want the server-side rendered
 * page to have the same max age.
 *
 * Example usage:
 *
 * const onResponse = useFetchInterceptor()
 * const { data } = await useFetch('/api/search/fulltext', { onResponse })
 */
export default function (): UseFetchInterceptor {
  // Return the callback only on the server.
  if (import.meta.server) {
    const event = useRequestEvent()

    return {
      onResponse: function (ctx) {
        // Get the cache tags.
        const tags = ctx.response.headers.get('surrogate-key')?.split(' ') || []

        // Get the cache control.
        const control = ctx.response.headers.get('surrogate-control') || ''

        useCDNHeaders((cdn) => {
          cdn.addTags(tags)

          // Parse the cache control header.
          const parsed = cdn._control.parse(control)

          if (parsed.maxAge) {
            cdn.setNumeric('maxAge', parsed.maxAge)
          }
          if (parsed.staleIfError) {
            cdn.setNumeric('staleIfError', parsed.staleIfError)
          }
          if (parsed.staleWhileRevalidate) {
            cdn.setNumeric('staleWhileRevalidate', parsed.staleWhileRevalidate)
          }
          if (parsed.private) {
            cdn.private()
          }
        }, event)
      },
    }
  }

  const config = useRuntimeConfig()

  return {
    onRequest: function (ctx) {
      if (!ctx.options.params) {
        ctx.options.params = {}
      }

      ctx.options.params.__h = config.public.buildHash
    },
  }
}
